.files-wrapper[data-v-3611606f] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.file-tag-list[data-v-3611606f] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 10px 10px 0px 0px;
}
.file-name[data-v-3611606f] {
  display: block;
  max-width: 150px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.file-list-preview[data-v-3611606f] {
  cursor: pointer;
  margin: 0 5px 0 20px;
}
.file-list-replace[data-v-3611606f] {
  cursor: pointer;
  margin: 0 5px;
}
.file-list-delete[data-v-3611606f] {
  cursor: pointer;
  color: red;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
  margin-left: 5px;
}
